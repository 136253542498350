<template>
  <div class="content">
    <a-breadcrumb separator=">" class="breadcrumb">
      <a-breadcrumb-item>
        <a @click.prevent="$router.push('/index')">首页</a>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        个人中心
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="account-content">
      <Menu />
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './compontend/Menu.vue'

export default {
  components: {
    Menu
  }
}
</script>

<style lang="less" scoped>
.content {
  @media screen and (max-width: 1440px){
    padding: 0 15px;
  }
}
.account-content {
  max-width: 1440px;
  min-width: 990px;
  margin: 0 auto;
  margin-bottom: 64px;
  display: flex;
  border: 1px solid #e8e8e8;
  .container {
    width: 100%;
    background: #fff;
    padding: 16px 28px;
  }
}
</style>